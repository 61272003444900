import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'login-example-3',
  templateUrl: './login3.component.html',
  styleUrls: ['./login3.component.css']
})
export class Login3Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
