import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountComponent } from './account/account.component';
import { BillpayComponent } from './account/billpay/billpay.component';
import { DummyComponent } from './account/dummy/dummy.component';
import { HoaComponent } from './account/hoa/hoa.component';
import { AuthGuard } from './auth/auth-guard.service';
import { AuthComponent } from './auth/auth.component';
import { LoginGuard } from './auth/login-guard.service';

const appRoutes: Routes = [
    { path: '', canActivate:[LoginGuard], component: AuthComponent },
    { 
        path: 'account', canActivate:[AuthGuard], component: AccountComponent, children: [
            { path: 'hoa', canActivate:[AuthGuard], component: HoaComponent },
            { path: 'billpay', canActivate:[AuthGuard], component: BillpayComponent },
            { path: 'dummy', component: DummyComponent }
        ]
    }
];

@NgModule({
    declarations: [],
    imports: [ RouterModule.forRoot( appRoutes ) ],
    exports: [ RouterModule ],
    providers: [AuthGuard, LoginGuard],
})
export class AppRoutingModule {}