import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-hoa',
  templateUrl: './hoa.component.html',
  styleUrls: ['./hoa.component.css']
})
export class HoaComponent implements OnInit {

  // reportUrl: SafeResourceUrl = "";
  reportUrl = "";
  faExternal = faExternalLinkAlt;
  year: number | null = null;

  constructor( private authService: AuthService, private sanitizer: DomSanitizer, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
        if (params.year) {
            this.year = params.year;
        }
    });
    if (!this.year) {
        this.year = new Date().getFullYear();
    }
    //console.log("load report url", this.year);
    this.reportUrl = environment.endpoint + "/reports/owner/?uid=" + this.authService.currentUser?.id + "&token="+ this.authService.currentUser?.token+"&report=hoa&year="+this.year+"#zoom=90";
  }

}
