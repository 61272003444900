import { Injectable } from '@angular/core';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export interface AlertButton {
    title: string,
    btn_class: string,
    onClick: any,
    icon?: any
}

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    showAlert: Boolean = false;

    size: string = "modal-md";
    showFooter: boolean = false;
    title: string = "";
    loading: boolean = false;
    body: string = "";
    title_icon?: any = null;
    title_color: string = "";
    syncing: boolean = false;

    faCheck = faCheckCircle;
    faTimesCircle = faTimesCircle;

    buttons: AlertButton[] = [];
    onCloseAction: any = null;

    setAlert( title: string, body: string, size?: string, buttons?: AlertButton[] ){
        this.title = title;
        this.body = body;
        if( size ){
            this.size = size;
        }
        if( buttons ){
            this.showFooter = true;
            this.buttons = buttons;
        } else {
            this.showFooter = false;
        }
        this.showAlert = true;
    }

    setConfirmationAlert( title: string, body: string, confirmLabel: string, onConfirm?: any, confirmDanger?: boolean){
        this.size = "modal-md";
        //this.title_icon = faCheckCircle;
        this.title = title;
        //this.title_color = "text-success";
        this.body = body;
        this.showFooter = true;
        this.buttons = [
            {
                title: "Cancelar",
                btn_class: "btn-default",
                onClick: ()=>{
                    this.closeAlert();
                }
            },
            {
                title: confirmLabel,
                btn_class: confirmDanger ? "btn-danger": "btn-primary",
                onClick: () => {
                    if( onConfirm ){
                        onConfirm();
                    }
                }
            }
        ];
        this.showAlert = true;
    }

    setSuccessAlert( title: string, body: string, action?: any ){
        this.size = "modal-md";
        this.title_icon = faCheckCircle;
        this.title = title;
        this.title_color = "text-success";
        this.body = body;
        this.showFooter = true;
        this.buttons = [
            {
                title: "Cerrar",
                btn_class: "btn-default",
                onClick: ()=>{
                    this.closeAlert();
                    if( action ){
                        action();
                    }
                }
            }
        ];
        this.showAlert = true;
        if( action ){
            this.onCloseAction = action;
        }
    }

    setErrorAlert( title: string, body: string, action?: any ){
        this.size = "modal-md";
        this.title_icon = faTimesCircle;
        this.title = title;
        this.title_color = "text-danger";
        this.body = body;
        this.showFooter = true;
        this.buttons = [
            {
                title: "Cerrar",
                btn_class: "btn-default",
                onClick: ()=>{
                    this.closeAlert();
                    if( action ){
                        action();
                    }
                }
            }
        ];
        this.showAlert = true;
        if( action ){
            this.onCloseAction = action;
        }
    }

    resetAlert(){
        this.size = "modal-md";
        this.showFooter = false;
        this.title = "";
        this.loading = false;
        this.body = "";
        this.title_icon = null;
        this.title_color = "";
        this.buttons = [];
        this.onCloseAction = null;
    }

    closeAlert(){
        if( !this.syncing ){
            if( this.onCloseAction ){
                this.onCloseAction();
            }
            this.showAlert = false;
            this.resetAlert();
        }
    }

}