import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  firstYear: number = 2021;
  years: {year: number, selected: boolean}[] = [];
  selectedYear: number = 2021;

  constructor(public authService: AuthService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
      this.loadYears();
  }

  onHoaClick(){
    if( window.innerWidth <= 768 || window.innerHeight<=500 ){
      //Mobile version (open in new tab)
      let reportURL = environment.endpoint + "/reports/owner/?uid=" + this.authService.currentUser?.id + "&token="+ this.authService.currentUser?.token+"&report=hoa&year="+ this.selectedYear;
      window.open(reportURL, "_blank");
    } else {
      //Desktop version
      this.openReport("hoa");
    }
  }

  onBillpayClick(){
    if( window.innerWidth <= 768 || window.innerHeight<=500 ){
      //Mobile version (open in new tab)
      let reportURL = environment.endpoint + "/reports/owner/?uid=" + this.authService.currentUser?.id + "&token="+ this.authService.currentUser?.token+"&report=billpay&year=" + this.selectedYear;
      window.open(reportURL, "_blank");
    } else {
      //Desktop version
      this.openReport("billpay");
    }
    //this.router.navigate(["billpay"], {relativeTo: this.route});
  }

  openReport( reportPath: string ){
    //Reload the component even if it is already created and the url is the same
    this.router.navigateByUrl('/account/dummy', {skipLocationChange: true}).then(()=>{
      this.router.navigate([reportPath], {queryParams: {year: this.selectedYear}, relativeTo: this.route});
    })
  }

  loadYears() {
    const current = new Date().getFullYear();
    for (let y = this.firstYear; y <= current; y++) {
        this.years.push({
            year: y,
            selected: y === current
        });
    }
    this.selectedYear = current;
    //console.log("years", this.years, `CURRENT: ${this.selectedYear}`);
  }

  onYearChange(event: Event) {
    let selected = (<HTMLSelectElement>event.target).value;
    this.years.forEach((year, indx) => {
        this.years[indx].selected = year.year === +selected;
        if (year.year === +selected) {
            this.selectedYear = year.year;
        }
    });
    //console.log("Selected year", selected, this.years, `CURRENT: ${this.selectedYear}`);
    this.router.navigateByUrl('/account/dummy', {skipLocationChange: true}).then(()=>{
        //Nothing
    });
  }

  onLogout(){
    this.authService.logout();
  }

}
