<div id='login-wrapper'>
    <div id="login-bg" class="login-bg"></div>
    <div id="login-bg-color" class="login-bg"></div>
    <div id='box-wrapper' >
        <div id="login-container">
            <div id="login-center">
                <div id="login-center-bg"></div>
                <div id="login-center-content">
                    <div id="logo-wrapper">
                        <img src="https://api.puertaprivadabills.com/images/puertaprivada-logo2.png" alt="">
                    </div>
                    <div id="login-txt">
                        <h2>Welcome to</h2>
                        <div>Condominiums Puerta Privada Statements Service</div>
                    </div>
                    <div class="">
                        <input type="text" class='form-control no-radius-bottom' name="lastName" placeholder="Last Name">
                    </div>
                    <div class="form-group">
                        <input type="password" class='form-control no-radius-top' name="password" placeholder="Password">
                    </div>
                    <div>
                        <button class="btn btn-login form-control tbold">SIGN IN</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>