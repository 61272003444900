import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Login1Component } from '../auth/login1/login1.component';
import { Login2Component } from '../auth/login2/login2.component';
import { Login3Component } from '../auth/login3/login3.component';
import { SafePipe } from '../shared/safe-url.pipe';
import { AccountComponent } from './account.component';
import { BillpayComponent } from './billpay/billpay.component';
import { HoaComponent } from './hoa/hoa.component';
import { DummyComponent } from './dummy/dummy.component';
import { AccountRoutingModule } from './account.routing';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [
        AccountComponent,
        HoaComponent,
        BillpayComponent,
        Login1Component,
        Login2Component,
        Login3Component,
        DummyComponent,
        SafePipe
    ],
    imports: [
        CommonModule,
        RouterModule,
        SharedModule
    ],
    exports: [],
    providers: [],
})
export class AccountModule {}