import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AlertComponent } from './alert/alert.component';

@NgModule({
    declarations: [
        AlertComponent
    ],
    imports: [
        FontAwesomeModule,
        CommonModule
    ],
    exports: [
        AlertComponent,
        CommonModule,
        FontAwesomeModule
    ],
    providers: [],
})
export class SharedModule {}