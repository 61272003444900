import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'login-example-1',
    templateUrl: './login1.component.html',
    styleUrls: ['./login1.component.css']
})
export class Login1Component implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}
