import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-billpay',
  templateUrl: './billpay.component.html',
  styleUrls: ['./billpay.component.css']
})
export class BillpayComponent implements OnInit {

  reportUrl = "";
  faExternal = faExternalLinkAlt;
  year: number | null = null;

  constructor( private authService: AuthService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
        if (params.year) {
            this.year = params.year;
        }
    });
    if (!this.year) {
        this.year = new Date().getFullYear();
    }
    this.reportUrl = environment.endpoint + "/reports/owner/?uid=" + this.authService.currentUser?.id + "&token="+ this.authService.currentUser?.token+"&report=billpay&year="+ this.year +"#zoom=90";
  }

}
