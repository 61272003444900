import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthComponent } from './auth.component';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule } from '@angular/common/http';
import { LoginGuard } from './login-guard.service';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [AuthComponent],
    imports: [ 
        CommonModule,
        FormsModule,
        FontAwesomeModule,
        HttpClientModule,
        SharedModule
    ],
    exports: [],
    providers: [LoginGuard],
})
export class AuthModule {}