import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'login-example-2',
  templateUrl: './login2.component.html',
  styleUrls: ['./login2.component.css']
})
export class Login2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
