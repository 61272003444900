<div id="main-wrapper">
    <div id="login-bg" class="login-bg"></div>
    <div id="login-bg-color" class="login-bg"></div>

    <div id="main">
        <div id="left">
            <div id='left-bg'></div>
            <div id='left-content'>
                <div id="logo-wrapper">
                    <img src="https://api.puertaprivadabills.com/images/puertaprivada-logo2.png" alt="">
                </div>
                <div id='welcome' class='text-center twhite'>
                    <h2 class='tcursive'>Welcome</h2>
                    <h4 class='tcursive'>{{authService.currentUser?.name}}</h4>
                    <p>
                        <a (click)="onLogout()">Logout</a>
                    </p>
                </div>
                <div id='actions' class='text-center'>
                    <div style='text-align: center;'>
                        <select (change)="onYearChange($event)" class="form-control" style='width:80%;display:inline-block;'>
                            <option *ngFor="let year of years" value="{{year.year}}" [selected]="year.selected">Year: {{year.year}}</option>
                        </select>
                    </div>
                    <button class="btn btn-custom-main btn-hoa" (click)="onHoaClick()">HOA Fees</button><br>
                    <button class="btn btn-custom-main btn-billpay" (click)="onBillpayClick()">Bill Pay</button>
                </div>
            </div>
        </div>
        <div id="right">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

