<div id='login-wrapper'>
    <div id="login-bg" class="login-bg"></div>
    <div id="login-bg-color" class="login-bg"></div>
    <div id='box-wrapper' >
        <div id="login-container">
            <div id="login-center">
                <div id="login-flexbox">
                    <div id="login-img">
                        <img src="https://api.puertaprivadabills.com/images/puertaprivadap.jpeg" alt="Puerta Privada">
                    </div>
                    <div id="login-box">
                        <div id="logo-wrapper">
                            <img src="https://api.puertaprivadabills.com/images/puertaprivada-logo2.png" alt="">
                        </div>
                        <div id="login-txt" class="text-center">
                            <h1>Welcome to</h1>
                            <div>Condominio Puerta Privada</div>
                            <div>Statements Service</div>
                        </div>
                        <div class="">
                            <input type="text" class='form-control no-radius-bottom' name="lastName" placeholder="Last Name">
                        </div>
                        <div class="form-group">
                            <input type="password" class='form-control no-radius-top' name="password" placeholder="Password">
                        </div>
                        <div>
                            <button class="btn btn-login form-control tbold">SIGN IN</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>