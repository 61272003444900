import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { AlertService } from '../shared/alert/alert.service';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit, OnDestroy {

  errorMessage = "";
  private userSub?: Subscription;
  isAuthenticated = false;
  faSpinner = faSpinner
  loggingIn = false;
  queryParams: any = null;

  constructor( public authService: AuthService, private router: Router, private activeRoute: ActivatedRoute, public alertService: AlertService) { }

  ngOnInit(): void {
    this.userSub = this.authService.user.subscribe( user => {
      this.isAuthenticated = !!user;
    });

    if( this.activeRoute.snapshot.queryParams.hasOwnProperty("uid") ){
        this.queryParams = this.activeRoute.snapshot.queryParams;
        this.alertService.setAlert( "Please wait", "Verificando credenciales...");
        this.authService.emailTokenLogin( this.queryParams.uid, this.queryParams.pid, this.queryParams.token ).subscribe(
            resData => {
                if( resData.auth ){
                    this.authService.isLoggedIn = true;
                    this.router.navigate(['/account/']);
                } else {
                    this.alertService.closeAlert();
                }
            }, 
            error => {
                this.alertService.setErrorAlert( "Error", error );
            }
        )
    }
  }

  onSubmit( form: NgForm){
    this.errorMessage = "";
    
    if( !form.valid ){
      return;
    }

    this.loggingIn = true;

    this.authService.login(form.value["lastName"], form.value["password"] ).subscribe(
      resData => {
        if( resData.auth ){

          this.authService.isLoggedIn = true;
          this.router.navigate(['/account/']);

        } else {
          this.errorMessage = resData.error;
          this.authService.isLoggedIn = false;
          this.authService.user.next(null);
        }
        this.loggingIn = false;
        
        console.log( resData );
      }, errorMessage => {
        this.errorMessage = errorMessage;
        this.authService.isLoggedIn = false;
        this.authService.user.next(null);
        this.loggingIn = false;
        console.log( "errorMessage", errorMessage );
      }
    );

    form.reset( { lastName: form.value["lastName"], password: "" } );
  }

  ngOnDestroy(){
    this.userSub?.unsubscribe();
  }

}
